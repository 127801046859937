import axios from "axios";

export default async function axiosInstance(data, endpoint, method = 'post') {
    const host = process.env.REACT_APP_API_URL || 'https://app2.sharedcontacts.com/';
    const url = new URL(`/${endpoint}`, host);
    return axios({
        method,
        url,
        data,
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
    }).catch((error) => {
        if (error.code !== "ERR_CANCELED") {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('The request was made and the server responded with a status code that falls out of the range of 2xx');
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log('The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js');
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Something happened in setting up the request that triggered an Error');
                console.log('Error', error.message);
            }
            console.log('error.config');
            console.log(error.config);
        }
    });
}
