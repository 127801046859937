import * as React from "react";
import {useState} from "react";
import {
    Autocomplete,
    Avatar,
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    Grid, InputLabel, MenuItem, Select,
    TextField,
    Typography,
    IconButton,
    Stack, FormControlLabel, Tooltip,
    Chip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {ErrorOutlined, CheckCircle, Sync, CloseOutlined, DeleteOutlineOutlined, Group} from '@mui/icons-material';
import {useSelector} from "react-redux";
import * as EmailValidator from 'email-validator';
import Lottie from "lottie-react";

// project import
import store from "../../store/store";
import {openShareLabelsDialog} from "../../store/reducers/dialogSlice";
import {
    changeUserRole,
    shareGroups,
    changeContacts,
    setGroupsToShare, unshareList,
    setUnShareLabelStatus,
} from "../../store/reducers/groupsSlice";
import {getSubscriptionData} from "../../store/reducers/userSlice";
import animation from '../../loading.json'
import {getSharedUsers} from "../../services/getSharedUsers";
import {ShareLabelIcon} from "../Contacts/ShareLabelIcon";
import {StyledDataGrid} from "../StyledDataGrid";

export default function ShareLabelDialog() {
    const open = useSelector((state) => state.dialogsOpen.shareLabels.value);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const shareableUsers = useSelector((state) => state.user.shareableUsers);
    const user = useSelector((state) => state.user.user);
    const subscriptionInitialized = useSelector((state) => state.user.subscriptionInitialized);
    const subscription = useSelector((state) => state.user.subscription);
    const contacts = useSelector((state) => state.contacts.contacts);
    const shareLabelStatus = useSelector((state) => state.groups.shareLabelStatus);
    const groupsToShare = useSelector((state) => state.groups.groupsToShare);
    const [rowHover, setRowHover] = React.useState('');
    const [manualDisableValue, setManualDisableValue] = React.useState(false);
    const [noLicensedEmailValues, setNoLicensedEmailValues] = React.useState([]);
    const [textValue, setTextValue] = React.useState('');
    const [emailsValues, setEmailsValues] = React.useState([]);
    const [inputChangeError, setInputChangeError] = React.useState(false);
    const [changeError, setChangeError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const [permission, setPermission] = useState('VIEW');
    const [notify, setNotify] = useState(false);

    if (!open) return null;
    const availableSeats = subscription.quantity - subscription.active_quantity;
    const loading = shareLabelStatus === 'loading';
    const handleOnMouseEnter = (event) => {
        setRowHover(event.currentTarget.dataset.id);
    };
    const handleOnMouseLeave = () => {
        setRowHover(null);
    };
    const handleClickUnshare = async (event, id) => {
        event.stopPropagation();
        await store.dispatch(unshareList({resources: [groupsToShare[0].resource], emails: [id]}));
        await store.dispatch(getSubscriptionData());
        await store.dispatch(setUnShareLabelStatus('succeeded'));
    };
    const getName = (params) => {
        return <Stack direction={'row'} spacing={1}
                      sx={{width: '100%', alignItems: 'center'}}>
            {params.row.shareType === 'GROUP' ? <Avatar><Group/></Avatar> : <Avatar src={params.row.photo}/>}
            <Stack>
                <Typography sx={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: 14,
                    fontFamily: "Roboto",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0.2px',
                }}>
                    {params.value || params.row.email}
                </Typography>
                {params.value && <Typography sx={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: 13,
                    fontFamily: "Roboto",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '18px',
                    letterSpacing: '0.2px',
                }}>
                    {params.row.email}
                </Typography>}
            </Stack>
        </Stack>
    }
    const columns = [
        {
            field: 'name',
            headerName: 'Name and email',
            // width: 280,
            flex: 1,
            renderCell: (params) => getName(params)
        },
        {
            field: 'sync_status',
            headerName: 'Status',
            // width: 226,
            flex: 1,
            renderCell: (params) => {
                if (params.row.shareType === 'GROUP') {
                    const group = groupsToShare[0];
                    const usersInDomainGroup = group.share.filter(user => user.domain_group_email === params.id);
                    const usersInDomainGroupCount = usersInDomainGroup.length || params.row.count || 0;
                    const usersWithStatusOK = usersInDomainGroup.filter(user => user.sync_status === 'OK');
                    const groupSyncStatus = usersInDomainGroupCount === usersWithStatusOK.length ? 'Shared' :
                        `Sharing ${usersWithStatusOK.length}/${usersInDomainGroupCount}`;
                    return (
                        <Stack direction={'row'} alignItems="center" justifyContent="center" spacing={1}>
                            {getSyncStatusIcon(groupSyncStatus === 'Shared' ? 'OK' : 'ON_QUEUE')}
                            <Typography
                                sx={{
                                    color: 'rgba(32, 33, 36, 1)',
                                    fontSize: 14,
                                    fontFamily: "Open Sans",
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: '24px',
                                    letterSpacing: '0',
                                }}
                            >
                                {groupSyncStatus}
                            </Typography>
                        </Stack>
                    );
                }
                return (
                    <Stack direction={'row'} alignItems="center" justifyContent="center" spacing={1}>
                        {getSyncStatusIcon(params.value)}
                        <Typography
                            sx={{
                                color: 'rgba(32, 33, 36, 1)',
                                fontSize: 14,
                                fontFamily: "Open Sans",
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '24px',
                                letterSpacing: '0',
                            }}
                        >
                            {getSyncStatusText(params.value)}
                        </Typography>
                    </Stack>
                )
            }
        },
        {
            field: 'permission',
            headerName: 'Permissions',
            // width: 182,
            flex: 1,
            renderCell: (params) => (
                <>
                    {groupsToShare.length === 1 && <PermissionsSelectForOneUser user={params.row}/>}
                </>
            )
        },
        {
            field: 'action',
            headerName: '',
            width: 60,
            sortable: false,
            renderCell: (params) => {
                return params.id === rowHover && params.row.role !== 'owner' ? <Tooltip
                    placement="right"
                    title='Unshare'
                >
                    <span>
                        <IconButton
                            onClick={(event) => handleClickUnshare(event, params.id)}
                        >
                            <DeleteOutlineOutlined
                                sx={{
                                    width: 24,
                                    height: 24,
                                    flexShrink: 0,
                                }}
                            />
                        </IconButton>
                    </span>
                </Tooltip> : null
            }
        },
    ];
    const group = groupsToShare.length !== 0 ? groupsToShare[0] : null;
    // console.time('getSharedUsers')
    const rows = getSharedUsers(group, user, contacts, shareableUsers);
    // console.timeEnd('getSharedUsers')
    const handleClose = () => {
        setHelperText('');
        setInputChangeError(false);
        setChangeError(false);
        setTextValue('');
        setEmailsValues([]);
        setManualDisableValue(false);
        setNoLicensedEmailValues([]);
        setPermission('VIEW');
        store.dispatch(setGroupsToShare({groups: []}));
        store.dispatch(openShareLabelsDialog(false));
    };
    const onInputChange = (newInputValue) => {
        if (newInputValue.trim() === '') {
            setInputChangeError(false);
            setHelperText('');
            setNoLicensedEmailValues([]);
        } else if (!EmailValidator.validate(newInputValue)) {
            setInputChangeError(true);
            setNoLicensedEmailValues([]);
        } else {
            setInputChangeError(false);
            setHelperText('');
            const licensedUser = subscription.licensed_users.find(
                el => el.email === newInputValue
            );
            if (!licensedUser) {
                setNoLicensedEmailValues([{email: newInputValue}]);
                setManualDisableValue(true);
            } else {
                setManualDisableValue(false);
                setNoLicensedEmailValues([]);
            }
        }
        setTextValue(newInputValue);
    }
    const onChange = (event, values) => {
        const preparedValues = values.reduce((acc, item) => {
            return [...acc, item.email ? item : {email: item}];
        }, []);
        const uniqueValues = preparedValues.filter((obj, idx, arr) => idx === arr.findIndex((t) => t.email === obj.email));
        const noLicensedUsers = [];
        setChangeError(false);
        setHelperText('');
        const newValue = uniqueValues.map(option => {
            const newOption = {...option};
            let error = false;
            if (!EmailValidator.validate(newOption.email)) {
                error = true;
                setChangeError(true);
                setHelperText('Enter only valid email addresses');
            } else {
                let contact = subscription.licensed_users.find(
                    el => el.email === newOption.email
                );
                if (!contact) {
                    contact = shareableUsers.users.find(
                        el => el.email === newOption.email && el.domainUser
                    )
                    if (!contact) {
                        if (!newOption.group) {
                            noLicensedUsers.push(newOption.email);
                        }
                    }
                }
            }
            newOption.error = error;
            return newOption;
        });
        setNoLicensedEmailValues(noLicensedUsers);
        setEmailsValues(newValue);
        if (
            noLicensedUsers.length > 0 &&
            subscription.status === 2 &&
            subscription.plan_id !== 1
        ) {
            setManualDisableValue(true);
        } else {
            setManualDisableValue(false);
        }
        if (subscription.status === 0) {
            setManualDisableValue(true);
        }
        if (subscription.plan_id !== 1 && noLicensedUsers.length > availableSeats) {
            setManualDisableValue(true);
        }
    }
    const isShareDisabled = () => {
        return !((textValue.length > 0 || emailsValues.length > 0) &&
            subscriptionInitialized &&
            !manualDisableValue && !inputChangeError && !changeError);
    }

    const handleClickShare = async () => {
        let hasError = false;
        if (textValue.length > 0) {
            if (!emailsValues.find(value => value.email === textValue)) {
                emailsValues.push({email: textValue});
            }
            const performedValues = emailsValues.map(item => item.email ? item : {email: item});
            const uniqueValues = performedValues.filter((obj, idx, arr) => idx === arr.findIndex((t) => t.email === obj.email));
            const newValue = uniqueValues.map(option => {
                const newOption = option;
                let error = false;
                if (!EmailValidator.validate(newOption.email)) {
                    error = true;
                    hasError = true;
                }
                newOption.error = error;
                return newOption;
            });
            await setEmailsValues(newValue);
            setTextValue('');
        }
        if (!hasError && emailsValues.length > 0) {
            const payload = {
                emails: emailsValues.map(el => el.email),
                resources: groupsToShare.map(el => el.resource),
                notify,
                role: permission,
            };
            setEmailsValues([]);
            await store.dispatch(shareGroups(payload));
            await store.dispatch(getSubscriptionData());
            await store.dispatch(changeContacts({resource: currentGroup.resource}));
        }
    };
    const handleChangeNotify = (event) => {
        setNotify(event.target.checked);
    };
    const filterOptions = (options, {inputValue}) => {
        const loweredInputValue = inputValue.toLowerCase();
        return options.filter(option => (
            (option.name && option.name.toLowerCase().includes(loweredInputValue)) ||
            (option.email && option.email.toLowerCase().includes(loweredInputValue))
        ));
    };

    const LicenseMessage = () => {
        if (!subscriptionInitialized) return null;
        let msg = '';
        if (subscription.plan_id === 1) {
            if (subscription.status === 0) {
                msg = `Trial expired`
            } else {
                return null
            }
        } else if (subscription.status === 0) {
            msg = 'Your subscription has expired. Please subscribe';
        } else if (subscription.status === 2) {
            msg = 'You can add only users with your domain or with licences'
        } else if (noLicensedEmailValues.length > availableSeats) {
            msg = `You are out of licenses`
        } else {
            return null
        }
        return <Typography color='error'>{msg}</Typography>
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                id='ShareLabelDialog'
                PaperProps={{
                    sx: {
                        width: 840,
                        maxWidth: 840,
                        minWidth: 444,
                        height: groupsToShare.length === 1 && 'auto' || groupsToShare.length > 1 && '287',
                        backgroundColor: 'rgba(59, 125, 237, 1)'
                    }
                }}
            >
                <DialogTitle sx={{backgroundColor: 'rgba(59, 125, 237, 1)'}}>
                    <Stack spacing={0}>
                        <Grid container sx={{alignItems: 'center'}}>
                            <Grid item>
                                <Typography
                                    variant={'dialogTitle'}
                                    color={'rgba(255, 255, 255, 1)'}>{groupsToShare.length === 1 ? `Share label` : `Share group of labels`}</Typography>
                            </Grid>
                        </Grid>
                        <Box>
                            <Typography
                                noWrap
                                sx={{
                                    color: 'rgba(255, 255, 255, 1)',
                                    fontSize: 16,
                                    fontFamily: `"Roboto"`,
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    letterSpacing: '0.2px',
                                    marginBottom: 1,
                                }}
                            >
                                Invite collaborators to
                                access {groupsToShare.length === 1 ? `the label "${groupsToShare[0].name}"` : `selected group of labels`}
                            </Typography>
                        </Box>
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: 'rgba(255, 255, 255, 0.54)',
                        '&:hover': {
                            color: 'rgba(255, 255, 255, 0.87)',
                            backgroundColor: 'rgba(0, 0, 0, 0.16)'
                        },
                    }}
                >
                    <CloseOutlined/>
                </IconButton>
                <DialogContent dividers sx={{border: 0, backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                    <Stack spacing={1} sx={{marginTop: '15px'}}>
                        <Stack direction={'row'} spacing={3} sx={{alignItems: 'top'}}>
                            <Box sx={{width: 516}}>
                                <Autocomplete
                                    onChange={onChange}
                                    size='small'
                                    value={emailsValues}
                                    inputValue={textValue}
                                    onInputChange={(event, newInputValue) => onInputChange(newInputValue)}
                                    freeSolo
                                    id="share-label-emails"
                                    disableClearable
                                    autoHighlight
                                    options={shareableUsers.users}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.email}
                                    filterOptions={filterOptions}
                                    multiple
                                    fullWidth
                                    renderTags={(tagValue, getTagProps) => {
                                        return tagValue.map((option, index) => {
                                            return (
                                                <Chip {...getTagProps({index})}
                                                      size={'small'}
                                                      key={index}
                                                      sx={{
                                                          backgroundColor: option.error && 'rgba(236, 56, 40, 0.2)',
                                                      }}
                                                      label={
                                                          <Stack direction={'row'} spacing={1}
                                                                 sx={{marginLeft: '-5px', alignItems: 'center'}}>
                                                              {!option.group ? <Avatar sx={{width: 20, height: 20}}
                                                                                       src={option.photo}/> : <Avatar
                                                                  sx={{width: 20, height: 20}}><Group
                                                                  sx={{width: 20, height: 20}}/></Avatar>}
                                                              <Typography
                                                                  sx={{
                                                                      color: 'rgba(0, 0, 0, 0.87)',
                                                                      fontSize: 13,
                                                                      fontFamily: "Roboto",
                                                                      fontStyle: 'normal',
                                                                      fontWeight: 400,
                                                                      lineHeight: '18px',
                                                                      letterSpacing: '0.16px',
                                                                  }}
                                                              >
                                                                  {option.email}
                                                              </Typography>
                                                          </Stack>
                                                      }
                                                />
                                            )
                                        });
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}
                                             key={option.email}>
                                            {!option.group ? <Avatar
                                                sx={{width: 28, height: 28}}
                                                src={option.photo}/> : <Avatar
                                                sx={{width: 28, height: 28}}><Group/></Avatar>}
                                            <Typography variant={'settingsMenu'}
                                                        ml={2}>{option.name ? `${option.name} (${option.email})` : option.email}</Typography>
                                        </Box>
                                    )}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            error={changeError}
                                            helperText={helperText}
                                            label="Add emails"
                                            variant={'outlined'}
                                            placeholder=""
                                            InputProps={{
                                                ...params.InputProps,
                                                inputProps: {
                                                    ...params.inputProps,
                                                    'data-lpignore': 'true',  // The attribute for ignoring LastPass.
                                                },
                                                sx: {
                                                    color: 'rgba(0, 0, 0, 0.84)',
                                                    fontSize: '16px',
                                                    fontFamily: 'Roboto',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    lineHeight: '24px',
                                                    letterSpacing: '0.15px',
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    color: changeError ? 'rgba(211, 47, 47, 1)' : 'rgba(0, 0, 0, 0.60)',
                                                    fontSize: '16px',
                                                    fontFamily: 'Roboto',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    letterSpacing: '0.15px',
                                                },
                                            }}
                                        />
                                    }
                                />
                            </Box>
                            <Stack direction={'row'} spacing={3} sx={{height: 40, alignItems: 'center'}}>
                                <PermissionsSelect permission={permission} setPermission={setPermission}/>
                                <LoadingButton loading={loading}
                                               onClick={handleClickShare}
                                               variant="contained"
                                               disabled={isShareDisabled()}
                                               sx={{
                                                   width: 131,
                                                   minWidth: 131,
                                                   height: 36,
                                                   background: "rgba(59, 125, 237, 1)",
                                                   "&.Mui-disabled": {
                                                       background: "rgba(0, 0, 0, 0.08)"
                                                   }
                                               }}
                                               startIcon={<ShareLabelIcon isShareDisabled={isShareDisabled()}/>}
                                >
                                    <Typography
                                        variant={'button'}
                                        sx={{
                                            textTransform: "none",
                                            color: isShareDisabled() ? 'rgba(0, 0, 0, 0.32)' : '#FFF',
                                            letterSpacing: 0,
                                        }}
                                    >
                                        Share label
                                    </Typography>
                                </LoadingButton>
                            </Stack>
                        </Stack>
                        <Stack paddingLeft={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={notify}
                                        onChange={handleChangeNotify}
                                        name='notify'
                                        color='primary'
                                        sx={{width: 40, height: 40}}
                                    />
                                }
                                sx={{
                                    width: 200,
                                    height: 40,
                                    '.MuiFormControlLabel-label': {
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        fontSize: 14,
                                        fontFamily: "Roboto",
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        letterSpacing: '0.15px',
                                    }

                                }}
                                label='Notify people by email'
                            />
                            <LicenseMessage/>
                        </Stack>
                        {groupsToShare.length === 1 && <Grid item md={12}>
                            <div
                                style={{
                                    width: '100%',
                                    height: `calc(100vh - 500px)`,
                                }}
                            >
                                <StyledDataGrid
                                    disableRowSelectionOnClick
                                    rows={rows}
                                    columns={columns}
                                    autoPageSize
                                    rowHeight={72}
                                    slotProps={{
                                        row: {
                                            onMouseEnter: handleOnMouseEnter,
                                            onMouseLeave: handleOnMouseLeave,
                                        },
                                    }}
                                    components={{NoRowsOverlay}}
                                />
                            </div>
                        </Grid>}
                    </Stack>
                </DialogContent>
                <DialogActions sx={{backgroundColor: 'rgba(255, 255, 255, 1)'}}>
                    <Button onClick={handleClose}><Typography variant={'dialogButton'}
                                                              color={'rgba(0, 0, 0, 0.54)'}>Close</Typography></Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function PermissionsSelect({permission, setPermission}) {
    const handleChange = (event) => {
        setPermission(event.target.value);
    };
    return (
        <FormControl
            variant="outlined"
            size='small'
            sx={{width: 160}}
        >
            <InputLabel
                id="demo-simple-select-label"
                sx={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                }}
            >
                Permissions
            </InputLabel>
            <Select
                sx={{
                    color: 'rgba(0, 0, 0, 0.84)',
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '23px',
                    letterSpacing: '0.15px',
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                color: 'rgba(0, 0, 0, 0.84)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            },
                        },
                    },
                }}
                labelId="demo-simple-select-label"
                id="permission-select"
                value={permission}
                label="Permission"
                onChange={handleChange}
            >
                <MenuItem value={'VIEW'}>View only</MenuItem>
                <MenuItem value={'EDIT'}>Can edit</MenuItem>
                <MenuItem value={'SHARE'}>Can reshare</MenuItem>
            </Select>
        </FormControl>
    );
}

function PermissionsSelectForOneUser({user}) {
    const groupsToShare = useSelector((state) => state.groups.groupsToShare);
    const [permission, setPermission] = useState('');
    const userShareInfo = groupsToShare[0].share.find(item => item.email === user.email) || groupsToShare[0].domains_groups_share.find(item => item.email === user.email);
    const handleChange = async (event) => {
        setPermission(event.target.value);
        const data = {
            group: groupsToShare[0].resource,
            userId: userShareInfo.user_id,
            email: userShareInfo.email,
            role: event.target.value,
        }
        store.dispatch(changeUserRole(data));
    };
    if (user.role === 'owner') {
        return <Typography
            sx={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: 14,
                fontFamily: "Roboto",
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.2px',
            }}
        >
            Owner
        </Typography>
    }
    return (
        <FormControl variant="standard" fullWidth>
            <Select
                // disabled={user.role === 'owner'}
                disableUnderline
                sx={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    fontSize: 14,
                    fontFamily: "Roboto",
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    letterSpacing: '0.2px',
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiMenuItem-root': {
                                color: 'rgba(0, 0, 0, 0.87)',
                                fontSize: '16px',
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                            },
                        },
                    },
                }}
                labelId="demo-simple-select-label"
                id="permission-select"
                value={permission || user.role}
                label="Permission"
                onChange={handleChange}
            >
                <MenuItem value={'VIEW'}>View only</MenuItem>
                <MenuItem value={'EDIT'}>Can edit</MenuItem>
                <MenuItem value={'SHARE'}>Can reshare</MenuItem>
            </Select>
        </FormControl>
    );
}

const NoRowsOverlay = () => (
    <Stack height="100%" alignItems="center" justifyContent="center">
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                width: '243px',
            }}
        >
            <Typography
                sx={{
                    color: 'rgba(60, 64, 67, 1)',
                    fontFamily: 'Open Sans',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '24px',
                    letterSpacing: '-0.72px',
                }}
            >
                There are no contacts
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(0, 0, 0, 0.60)',
                    fontFamily: 'Open Sans',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '-0.56px',
                    textAlign: 'center',
                }}
            >
                Contacts with whom you share this label will be displayed here
            </Typography>
        </Box>
    </Stack>
);

const getSyncStatusText = (status) => {
    let result = '';
    switch (status) {
        case 'ON_QUEUE':
            result = 'Sharing...';
            break;
        case 'OK':
            result = 'Shared';
            break;
        case 'WAITING_LABEL':
            result = 'Waiting for access';
            break;
        case 'WAITING_CONTACTS':
            result = 'Sharing...';
            break;
        default:
            result = 'Waiting for access';
    }
    return result;
}

const getSyncStatusIcon = (status) => {
    let result = '';
    switch (status) {
        case 'ON_QUEUE':
            result = <Box sx={{width: 24, height: 24}}><Lottie animationData={animation} loop/></Box>;
            break;
        case 'OK':
            result = <CheckCircle color={'success'}/>;
            break;
        case 'WAITING_LABEL':
            result = <ErrorOutlined color={'error'}/>;
            break;
        case 'WAITING_CONTACTS':
            result = <Box sx={{width: 24, height: 24}}><Lottie animationData={animation} loop/></Box>;
            break;
        default:
            result = <ErrorOutlined color={'error'}/>;
    }
    return result;
}
